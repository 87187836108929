import React from "react"
import Link from 'gatsby-link'
import styled from 'styled-components'

const List = styled.ul`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	list-style: none;
	padding: 0;
`

const NavLink = styled(Link)`
  box-shadow: none;
	background-image: none;
	margin-left: -1.5rem;

  &:hover {
    background-image: none;
  }
`

const NavigationItem = ({ previousPage, nextPage }) => (
  <nav>
    <List>
      <li>
        {previousPage && (
          <NavLink to={previousPage.fields.slug} rel="prev">
            ← {previousPage.frontmatter.title}
          </NavLink>
        )}
      </li>
      <li>
        {nextPage && (
          <NavLink to={nextPage.fields.slug} rel="next">
            {nextPage.frontmatter.title} →
          </NavLink>
        )}
      </li>
    </List>
  </nav>
)

export default NavigationItem
