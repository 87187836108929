import React from "react"
import { rhythm, scale } from "../utils/typography"

const Post = ({ content }) => (
  <article>
    <header>
      <h1
        style={{
          marginTop: rhythm(1),
          marginBottom: 0,
        }}
      >
      	{content.frontmatter.title}
      </h1>
      <p
        style={{
          ...scale(-1 / 5),
          display: `block`,
          marginBottom: rhythm(1),
        }}
      >
        {content.frontmatter.date}
        {' · '}
        {content.fields.readingTime.text}
      </p>
    </header>
    <section dangerouslySetInnerHTML={{ __html: content.html }} />
    <hr
      style={{
        marginBottom: rhythm(1),
      }}
    />
  </article>
)

export default Post
